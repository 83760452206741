$colorBase: #000000;
$colorBgBase: #FFFFFF;
$colorSubNav: #515151;
$colorBgSubMenu: #F0F0F0;
$colorBgTabs: $colorBgSubMenu;
$colorTabs: $colorSubNav;
$colorFooter: $colorBgSubMenu;
$colorBgFooter: $colorSubNav;
$colorTableCellBorder: $colorBgSubMenu;
$colorBgTableAlternativeRows: $colorBgSubMenu;
$colorInlineQuote: $colorSubNav;
$colorFormText: $colorSubNav;
$colorUnselected: #c6bdbd;


$colorInverted: $colorBgBase;
$colorInvertedHover: #c6bdbd;

$colorCoverGradientLight: #FFFFFF;
$colorCoverGradientDark: #000000;

$colorSecondaryInfo: $colorSubNav;

$colorInput: $colorSubNav;
$colorBgInput: transparent;
$colorInputBorder: $colorSubNav;
$colorError: #FF0000;
$colorSearchInput: $colorSubNav;
$colorBgSearchInput: $colorBgSubMenu;

$colorBgAlert: transparentize($colorBgBase, 0.1);
$colorBgModal: transparentize($colorBase, 0.2);
$colorVideoOverlay: transparentize($colorBase, 0.7);

$colorHighlightBase: #14F5B4;
$colorHighlightGeneric: $colorBgSubMenu;

$colorIndicatorBg: #c6bdbd;



// $colorHighlightBaseText: #00b37d;
$colorHighlightBaseText: #01865E;


$colorHighlightFaculties: (
	biology: (default: #92B168, text: #708f4a),
	chemistry: (default: #A08CAA, text: #84698d),
	educational-science: (default: #F08C50, text: #c36226),
	history: (default: #7DB4BE, text: #4e8f9a),
	public-health: (default: #DC5A5A, text: #c55757),
	linguistics: (default: #708296, text: #70859c),
	mathematics: (default: #a37f32, text: #b08323),
	medicine: (default: #145F7D, text: #145f7d),
	physics: (default: #649696, text: #4b7d7d),
	law: (default: #8C8C96, text: #6d6d77),
	psychology: (default: #8C3250, text: #85314a),
	sociology: (default: #bfd02f, text: #a4474c),
	technology: (default: #558CA0, text: #47798c),
	economics: (default: #74799b, text: #46506e),
	library: (default: #008965, text: #008965),
);

$colorEkvvBgBase: #F3F3F3;
$colorEkvvBgAlternative: #D8D8D8;
$colorEkvvEventBg: $colorBgSubMenu;
$colorEkvvEventBgHighlight: $colorHighlightBase;

$colorAlphabeticalLetterInactive: #B2B2B2;
$colorEkvvLinkAlternate: $colorAlphabeticalLetterInactive;
$colorEkvvFilterBorder: $colorAlphabeticalLetterInactive;
$colorEkvvFilterFont: $colorSubNav;

$colorAlphabeticalDlBorder: $colorHighlightBase;

$colorEkvvAssignmentPlanLevelFree: #26CD7A;
$colorEkvvAssignmentPlanLevelMedium: #EF8826;
$colorEkvvAssignmentPlanLevelHard: #EF2626;

$colorTextImportant: $colorEkvvAssignmentPlanLevelHard;
$colorAlertBorder: $colorEkvvAssignmentPlanLevelHard;

$colorBgSemesterTable: $colorEkvvBgBase;
$colorEkvvTableBorder: $colorHighlightBase;

$colorCoursesFeaturesLine: $colorBgSubMenu;
$colorCoursesIconDefault: #646464;


$focusOutlineStyle: solid;
$focusOutlineColor: $colorBase;
$focusOutlineColorInverted: $colorBgBase;

$transparenceHighlightBg: 0.5;
